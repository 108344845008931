export default {
    groupBy(arr, key) {
        const groupedData = {};

        arr.forEach(item => {
            const keyValue = item[key];

            if (!groupedData[keyValue]) {
                groupedData[keyValue] = [];
            }

            groupedData[keyValue].push(item);
        });

        return groupedData;
    },

    productsHasLabel(orders, propName) {
        const allProducts = orders.reduce(
            (products, order) => [...products, order.product],
            []
        );

        return Boolean(allProducts.find(product => product[propName]));
    }
}
