import {
    GET_BREAKDOWNS_FROM_DB as DEFAULT_GET_BREAKDOWNS_FROM_DB,
    ENABLE_EPEI_REQUIRE_BEG_CASH_FUND,
    ENABLE_UPDATED_GENERATED_BY,
    ENABLE_HIDE_NO_SERVICE_CHARGE,
    ENABLE_CASH_SALES_BREAKDOWN,
    ENABLE_UPDATED_REPORT_TIMESTAMP,
    USE_SM_MARKETS_OR_FORMAT,
    OFFLOAD,
    ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT,
} from '@/spa/constants';
import { mapMutations, mapState } from 'vuex';
import {get, isEmpty, sumBy, sum} from 'lodash';
import { convert } from 'html-to-text';
import zReadHtml from '@/spa/components/templates/print/xzread.html';
import newXZReadHtml from '@/spa/components/templates/print/new_xzread.html';
import customSectionsHtml from '@/spa/components/templates/print/custom_xzread_sections.html';
import zReadSectionsHtml from '@/spa/components/templates/print/xzread_sections_html.json';
import { checkCloudConnection } from '@/spa/plugins/axios';
import { print} from "@/spa/services/printer-service";
import seriesService from "@/spa/services/series-service";
import {calculatePmixSummary} from "@/mobile_bridge/offload/offload-receipt";
let GET_BREAKDOWNS_FROM_DB = DEFAULT_GET_BREAKDOWNS_FROM_DB;

const SeparateSectionMixin = {
    data() {
        return {
            lastKot: null,
            lastBillNum: null,
            lastReceiptNum: null,
            lastVoidBillNum: null,
        }
    },

    async mounted() {
        this.lastKot = await seriesService.getLastKotNum();
        this.lastBillNum = await seriesService.getLastBillNum();
        this.lastReceiptNum = await seriesService.getLastReceiptNum();
        this.lastVoidBillNum = await seriesService.getLastVoidReceiptNum();
    },

    computed: {
        ...mapState('user', ['userTypeName', 'firstName']),

        canPmix() {
            return this.$can(this.PERMISSIONS.PRINT_PMIX) && this.sectionsValues.hasOwnProperty('pmix');
        },

        grossSection() {
            return ENABLE_HIDE_NO_SERVICE_CHARGE
            ? `<tr>
                <td valign="top">Gross</td>
                <td valign="top">___GROSS_W_SERVICE_CHARGE___</td>
            </tr>`
            : `<tr>
                    <td valign="top">Gross Sales</td>
                    <td valign="top">___GROSS_W_SERVICE_CHARGE_WO_VOID___</td>
                </tr>
                <tr>
                    <td valign="top">Gross w/ Void</td>
                    <td valign="top">___GROSS_W_SERVICE_CHARGE___</td>
                </tr>
                ___GROSS_WO_SERVICE_CHARGE_WO_VOID_SECTION___`;
        },

        serviceChargeSection() {
            return ENABLE_HIDE_NO_SERVICE_CHARGE
            ? ''
            : `<tr>
                <td valign="top">Service Charge</td>
                <td valign="top">___SERVICE_CHARGE___</td>
            </tr>`;
        },

        generatedBy() {
            return ENABLE_UPDATED_GENERATED_BY
                ? `${this.firstName}-${this.userTypeName}`
                : this.username
        },

        cashSalesSection() {
            return ENABLE_CASH_SALES_BREAKDOWN
                ? `<tr>
                    <td valign="top" colspan="2">Cash Sales</td>
                </tr>
                <tr>
                    <td valign="top">&nbsp;&nbsp;Expected:</td>
                    <td valign="top">___TOTAL_CASH_SALES_EXPECTED___</td>
                </tr>
                <tr>
                    <td valign="top">&nbsp;&nbsp;Declared:</td>
                    <td valign="top">___TOTAL_CASH_SALES_DECLARED___</td>
                </tr>`
                : `<tr>
                    <td valign="top">Cash Sales</td>
                    <td valign="top">___TOTAL_CASH_SALES___</td>
                </tr>`;
        },

        reportTimestampSection() {
            return ENABLE_UPDATED_REPORT_TIMESTAMP
                ? `<tr>
                    <td valign="top" style="font-size: 10px;">Printing Date</td>
                    <td valign="top" style="font-size: 10px;">___RECIEPT_PRINTED_DATE_ONLY___</td>
                </tr>
                <tr>
                    <td valign="top" style="font-size: 10px;">Printing Time</td>
                    <td valign="top" style="font-size: 10px;">___RECIEPT_PRINTED_TIME_ONLY___</td>
                </tr>`
                : `<tr>
                    <td valign="top" style="font-size: 10px;">Receipt printed on</td>
                    <td valign="top" style="font-size: 10px;">___RECIEPT_PRINTED_DATE___</td>
                </tr>`
        },
    },

    methods: {
        ...mapMutations('modals', ['setIsCashFloatModalOpen']),

        async printSeparateSections() {
            const response = await this.$openApproverModal();
            if (!response.success) {
                if (response.cancelled) {
                    this.$swal.warning('Print Separate Sections cancelled');
                }

                return;
            }
            let printConfig = {};

            let sections = await this.generateSeparateSections(JSON.parse(this.recieptDetails.print_zread_sections));

            let htmlArr = [];

            sections.forEach(s => {
                let printData = this.generatePrintData(s, customSectionsHtml, 'XZRead_Custom_Sections');
                htmlArr.push(printData.html);
            });

            print({
                print_type: 'XZRead_Custom_Sections',
                html: htmlArr
            }, printConfig);
        },

        async generateSeparateSectionValues() {
            //set filterDate to target date
            this.dateFilter = moment(this.targetPosDate).format("YYYY-MM-DD");

            // DATA POPULATION
            // OVER SHORT, PMIX
            let values = {};

            if (GET_BREAKDOWNS_FROM_DB && this.selectedDateIsOlderThanPosDate) {
                if (await checkCloudConnection()) {
                    let fromOnline
                    try {
                        fromOnline = await this.generateReprintData(this.selectedDateIsOlderThanPosDate);

                        values['___TOTAL_OVER_SHORT___'] = this.$filters.formatPrice(sumBy(Object.values(fromOnline.cash_drawers), 'over_short'));
                        values['___TERMINAL_ID___'] = fromOnline.location_pos.terminal_id;
                        values['___PRODUCT_MIX_SUMMARY___'] = this.canPmix ? this.generateProductMixString(fromOnline.product_mix_summary, fromOnline.product_mix_grand_total) : '';

                    } catch {
                        console.warn("Section Printing: Cannot get data from db");
                        return {};
                    }
                } else if (dayendData) {
                    await this.generateData();
                    values['___TOTAL_OVER_SHORT___'] = this.$filters.formatPrice(dayendData.day_end?.over_short ?? 0);
                    values['___TERMINAL_ID___'] = this.shiftTable.terminal_id;
                    values['___PRODUCT_MIX_SUMMARY___'] = '';

                } else {
                    this.$swal.warning('No internet Connection, Kindly connect to the internet to get previous zreads.');
                }
            } else {

                values['___TOTAL_OVER_SHORT___'] = this.$filters.formatPrice(this.totalOverShortAmount);
                values['___TERMINAL_ID___'] = this.shiftTable.terminal_id;
                values['___PRODUCT_MIX_SUMMARY___'] = this.canPmix ? this.generateProductMixString(this.generatePmixData(), this.computePmixGrandTotal(this.generatePmixData())) : '';

            }

            //for generic header values
            values['___RECIEPT_PRINTED_DATE___'] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            values['___POS_DATE___'] = this.selectedDateIsOlderThanPosDate ? moment(this.dateFilter).format("YYYY-MM-DD") : moment(this.posDate).format("YYYY-MM-DD");
            values['___SHIFT_NO___'] = this.shiftTable.shift;
            values['___GENERATED_BY___'] = this.generatedBy;

            Object.keys(this.sectionsValues).forEach(sn => {
                let html = this.getSectionHtml(sn);
                let sectionHeader = this.getSectionHtml(sn + '_header');

                html = sectionHeader + html;

                //replace with values
                var RE = new RegExp(Object.keys(values).join("|"), "gi");
                html = html.replace(RE, function (matched) {
                    return values[matched];
                });

                this.sectionsValues[sn] = html;
            });

            return this.sectionsValues;
        },

        async generateSeparateSections(sectionNames) {
            let sections = [];

            let payload = {}

            const sectionsValues = await this.generateSeparateSectionValues();

            sectionNames.forEach(sn => {
                let html = sectionsValues['header'] + sectionsValues[sn];

                //check if section name exists in sectionValues
                if(sectionsValues.hasOwnProperty(sn) && sectionsValues[sn].length > 0) {
                    let html = sectionsValues['header'] + sectionsValues[sn];

                    // only insert those with values
                    if(html) {
                    payload = {
                        ___REPORT_TYPE___ : this.reportTypeTitle,
                        ___REPRINT___: this.selectedDateIsOlderThanPosDate ? 'REPRINT' : '',
                        ___CUSTOM_SECTION___: html,
                    };

                    sections.push(payload);
                    }
                }
            });

            return sections;
        },

        getSectionHtml(key) {
            return get(zReadSectionsHtml, key, '');
        },

        generatePrintData(payload, printTemplate = '', printType = 'Report') {
            const template = this.$can(this.PERMISSIONS.OLD_ZREAD_FORMAT) ? zReadHtml : newXZReadHtml;
            printTemplate = printTemplate ? printTemplate : template;
            var RE = new RegExp(Object.keys(payload).join("|"), "gi");
            printTemplate = printTemplate.replace(RE, function (matched) {
                return payload[matched];
            });

            console.log('================================================');
            console.log(convert(printTemplate, { preserveNewlines: true }));
            console.log('================================================');

            return {
                print_type: printType,
                html: printTemplate,
            };
        },

        showRequireCashFundAlert() {
            if (!window.isProd || window.isProd && ENABLE_EPEI_REQUIRE_BEG_CASH_FUND) {
                Swal.fire({
                    title: 'Please declare beginning cash fund.',
                    icon: 'warning',
                }).then((result) => {
                    if (result.isConfirmed) this.setIsCashFloatModalOpen(true);
                });

                return;
            }
        },

        createValueLabelRow(label, value, valueFormatter = v => this.$filters.formatPrice(v)) {
            return `<tr><td valign="top">${label}</td><td valign="top">${valueFormatter(value)}</td></tr>`;
        },

        createValueLabelRowWithHeader(parentObj) {
            return Object.keys(parentObj).map(parentKey => {
                let headerRow = `<tr><td valign="top" colspan="2">${parentKey}</td></tr>\n`;
                let childSection = Object.keys(parentObj[parentKey]).map(childKey => {
                  return this.createValueLabelRow(childKey, parentObj[parentKey][childKey])
                }).join("\n");

                return headerRow.concat(childSection).concat("\n");
            }).join("\n");
        },

        padWithZero(num) {
            return String(num || 0).padStart(20, '0');
        },

        toValueLabelMap(obj, valueKey = 'amount', labelKey = 'name', valueGetter = (obj, key) => obj[key]) {
            return Object.keys(obj).map(key => {
                return {
                [labelKey]: key,
                [valueKey]: valueGetter(obj, key),
                }
            });
        },

        generateDiscountString(discounts) {
            return discounts.map(discount => {
                return this.createValueLabelRow(discount.name, discount.amount);
            }).join("\n");
        },

        mapServiceType(serviceTypes) {
            return Object.keys(serviceTypes)
                .map(name => ({
                    name,
                    amount: serviceTypes[name].value,
                    count: serviceTypes[name].count,
                }));
        },

        generateServiceTypeString(serviceTypes) {
            if (!this.toggleState['sales-per-service-type']) return '';
            return serviceTypes.map(serviceType => {
                return `
                <tr><td valign="top">${serviceType.name}</td><td valign="top">${this.$filters.formatPrice(serviceType.amount)}</td></tr>
                <tr><td valign="top">Count:</td><td valign="top">${serviceType.count}</td></tr>
                `;
            }).join("\n");
        },

        generateHourlySalesString(hourlySales, locationId = null) {
            if (isEmpty(hourlySales)) {
                return "";
            }

            let total = 0;
            let totalQty = 0;

            let breakdown = "";
            for (const key in hourlySales) {
                const hourSale = hourlySales[key];
                total += hourSale.value;
                totalQty += hourSale.count;
                breakdown += `
                <tr>
                    <td valign="top" class="hourly-sales-time">
                        ${key.replaceAll(' ', '')}
                    </td>
                    <td valign="top" class="strlen">${hourSale.count.toFixed(0)}</td>
                    <td valign="top" class="strlen">
                        ${this.$filters.formatPrice(hourSale.value)}
                    </td>
                </tr>
                `
            }

            if (window.ENABLE_HOURLY_SALES_XZREAD || window.HOURLY_SALES_XZREAD_LOCATIONS.includes(locationId)) {
                return `
                    <tr class="hourly-sales-header">
                        <td valign="top" colspan="2">Hourly Sales</td>
                    </tr>
                    <tr class="hourly-sales-header">
                        <td valign="top" colspan="2"></td>
                    </tr>
                    <table id="hourly-sales-table">
                        ${breakdown}
                        <tr>
                            <td valign="top" colspan="3"></td>
                        </tr>
                        <tr>
                            <td valign="top" class="hourly-sales-total">
                                TOTAL:
                            </td>
                            <td valign="top" class="strlen">${String(totalQty)}</td>
                            <td valign="top" class="strlen">
                                ${this.$filters.formatPrice(total)}
                            </td>
                        </tr>
                    </table>`;
            } else {
                return '';
            }
        },

        generateIncomeHeadSalesString(revenueBreakdown) {
            if (!this.toggleState['income-head-sales']) return '';
            return revenueBreakdown.map(incomeHead => {
                return this.createValueLabelRow(incomeHead.name, incomeHead.daywise);
            }).join("\n");
        },

        generatePaymentMethodString(paymentMethods) {
            return paymentMethods.map(paymentMethod => {
                return this.createValueLabelRow(paymentMethod.name, paymentMethod.amount);
            }).join("\n");
        },

        generateProductMixString(mixData, grandTotalPmix) {
            let productMixSummary = Object.keys(mixData).length > 0 ? `
                <tr>
                    <td colspan="2">
                    <br>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        PRODUCT MIX SUMMARY
                    </td>
                </tr>
            ` : '';

            for (let serviceType in mixData) {
                productMixSummary += `
                    <tr>
                            <td valign="top" colspan="2" class="pt-4">Service Type: ${serviceType}</td>
                    </tr>
                `;
                for (let groupName in mixData[serviceType]) {
                    productMixSummary += `
                        <tr>
                                <td valign="top" colspan="2" class="pt-2">${groupName}</td>
                        </tr>`;
                        if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                            productMixSummary += `
                            <tr>
                                <td  colspan="2" valign="top">Description</td>
                            </tr>
                            `;
                        } else {
                            productMixSummary += `
                            <tr>
                                <td valign="top">Description</td>
                                <td valign="top">Qty</td>
                            </tr>
                            `;
                        }
                    for (let productName in mixData[serviceType][groupName]) {
                        if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                            productMixSummary += `
                                <tr>
                                    <td colspan="2" valign="top">${productName}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" valign="top">${String(mixData[serviceType][groupName][productName].qty).padStart(25, ' ')} ${String(mixData[serviceType][groupName][productName].orig_price.toFixed(2)).padStart(15, ' ')} ${String(mixData[serviceType][groupName][productName].total_price.toFixed(2)).padStart(15, ' ')}</td>
                                </tr>
                            `;
                        } else {
                            productMixSummary += `
                            <tr>
                                <td valign="top">${productName}</td>
                                <td valign="top">${mixData[serviceType][groupName][productName]}</td>
                            </tr>
                            `;
                        }
                    }

                    if(USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                        productMixSummary += `
                        <tr>
                            <td valign="top" colspan="2" class="pt-3">Total Quantity: ${Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b.qty, 0) }</td>
                        </tr>
                        <tr>
                            <td valign="top" colspan="2" class="pt-1">Total Amount: ${this.$filters.formatPrice(Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b.total_price, 0))}</td>
                        </tr>
                        `;
                    } else {
                        productMixSummary += `
                        <tr>
                            <td valign="top" class="pt-3">Total Quantity:</td>
                            <td valign="top" class="pt-3">${Object.values(mixData[serviceType][groupName]).reduce((a, b) => a + b, 0) }</td>
                        </tr>
                        `;
                    }
                    productMixSummary += `
                    <tr>
                        <td valign="top" colspan="2">
                                -------------------------------
                        </td>
                    </tr>`;
                }
                productMixSummary += '<tr><td colspan="2"></td></tr>';
            }

            let totalQty = grandTotalPmix?.grand_total_qty;
            let totalPrice = grandTotalPmix?.grand_total_amount;

            if (OFFLOAD.sqliteOffloadReceipt) {
                const total = calculatePmixSummary(mixData);
                totalQty = total.totalQty;
                totalPrice = total.totalPrice;
            }

            productMixSummary += `
                <tr>
                    <td valign="top" class="pt-3">Grand Total Qty:</td>
                    <td valign="top" class="pt-3">${ totalQty }</td>
                </tr>`;
            if (USE_SM_MARKETS_OR_FORMAT || ENABLE_PMIX_W_PRICE_QTY_AND_AMOUNT) {
                productMixSummary += `
                    <tr>
                        <td valign="top" class="pt-1">Grand Total Amt:</td>
                        <td valign="top" class="pt-1">${ this.$filters.formatPrice(totalPrice) }</td>
                    </tr>
                `;
            }

            return productMixSummary;
        },

        getOldGrandTotalString(value) {
            return this.createValueLabelRow('OLD GRAND TOTAL ', value);
        },

        async fetchLocationReportToggleState() {
            try {
                const { data } = await getReportToggleConfig(this.locationId);
                const xReadSections = data.data.xread_sections;
                const zReadSections = data.data.zread_sections;
                xReadSections.forEach(section => {
                    if (section in this.xToggleStates) (this.xToggleStates[section] = true);
                });
                zReadSections.forEach(section => {
                    if (section in this.zToggleStates) (this.zToggleStates[section] = true);
                });
            } catch (error) {
                this.$bvToast.toast(error.data?.message || 'An error occurred while fetching the location report toggle state.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        computePmixGrandTotal(productMix) {
            let totalGrandQty = []
            let totalGrandAmount = []
            Object.keys(productMix).map(serviceIdx => {
                Object.keys(productMix[serviceIdx]).map(groupIdx => {
                  totalGrandQty.push(Object.values(productMix[serviceIdx][groupIdx]).reduce((a, b) => a + b.qty, 0))
                  totalGrandAmount.push(Object.values(productMix[serviceIdx][groupIdx]).reduce((a, b) => a + b.total_price, 0))
                })
            })

            return {
              "grand_total_qty": sum(totalGrandQty),
              "grand_total_amount": this.$filters.formatPrice(sum(totalGrandAmount))
            };
        },
    },
};

export {
    SeparateSectionMixin,
}
