import { camelCase, isEmpty } from 'lodash';
import { getMe } from '@/spa/services/user-service';

import {
    PERMISSIONS,
    DEXIE_TABLES,
    ENABLE_PLACE_ORDER_PERMISSION_CHECKING,
    ENABLE_SETTLEMENT_PERMISSION_CHECKING,
    ENABLE_EMPLOYEE_CLOCK_IN_PERMISSION_CHECKING,
    ENABLE_SALES_CONSOLIDATOR,
    OFFLOAD,
    PERMISSION_TYPES,
    ACTIVE_USER,
} from '@/spa/constants';

import {dbService} from "@/spa/services/db-service";

import AccountTypes from '@/constants/account-types';
import MultiTerminalTypes from '@/constants/multi-terminal-types';
import {sqliteOffloadGetStorageValue} from "@/mobile_bridge/offload/receipt-model";

const terminal = JSON.parse(sessionStorage.getItem('terminal'));
const isMultiTerminal = terminal?.is_shared_terminal;

const defaultStateFactory = () => ({
    id: '',
    accountId: '',
    userTypeId: '',
    userTypeName: '',
    locationId: '',
    brandId: '',
    terminalId: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    accountTypeId: '',
    permissions: {},
    isMultiTerminal
});

export default {
    namespaced: true,

    state: defaultStateFactory(),

    mutations: {
        resetState(state) {
            Object.assign(state, defaultStateFactory());
        },
        setState(state, value) {
            Object.keys(value).forEach(k => {
                state[camelCase(k)] = value[k];
            });
        },
        setId(state, value) {
            state.id = value;
        },
        setAccountId(state, value) {
            state.accountId = value;
        },
        setUserTypeId(state, value) {
            state.userTypeId = value;
        },
        setUserTypeName(state, value) {
            state.userTypeName = value;
        },
        setLocationId(state, value) {
            state.locationId = value;
        },
        setBrandId(state, value) {
            state.brandId = value;
        },
        setTerminalId(state, value) {
            state.terminalId = value ?? 1;
        },
        setFirstName(state, value) {
            state.firstName = value;
        },
        setLastName(state, value) {
            state.lastName = value;
        },
        setEmail(state, value) {
            state.email = value;
        },
        setUsername(state, value) {
            state.username = value;
        },
        setAccountTypeId(state, value) {
            state.accountTypeId = value;
        },
        setPermissionKey(state, { key, value }) {
            state.permissions[key] = value;
        },
    },

    actions: {
        async fetchMe({ commit }) {
            commit('resetState');
            try {
                const activeUser = await sqliteOffloadGetStorageValue(ACTIVE_USER);

                if (!isEmpty(activeUser?.currentShift)) {
                    const terminal = JSON.parse(sessionStorage.getItem('terminal'))
                    const {currentShift, ...rest} = activeUser;
                    rest.terminal_id = currentShift?.terminal_id ?? terminal.id;

                    commit('setState', rest);

                    return;
                }

                const response = await getMe();
                commit('setState', response.data);
            } catch (e) {
                console.error(e);
            }
        },

        async populatePermissions({ state, commit }) {
            if (OFFLOAD.sqliteOffloadPermission) {
                const lb = new LocationBridge();
                const pb = new PermissionBridge();

                const permissions = [
                    {key: PERMISSIONS.EOD_REPORT, value: await pb.can(UserPermissionBridge, PERMISSION_TYPES.EOD_REPORT, state.userTypeId)},
                    {key: PERMISSIONS.PAX_DISABLED, value: await pb.can(LocationBridge, PERMISSION_TYPES.PAX_DISABLED) },
                    {
                        key: PERMISSIONS.PLACE_ORDER,
                        value: (ENABLE_PLACE_ORDER_PERMISSION_CHECKING
                            ? await pb.can(UserPermissionBridge, PERMISSION_TYPES.CREATE_TRANSACTIONS, state.userTypeId)
                            : true)
                    },
                    {
                        key: PERMISSIONS.SETTLE_ORDER,
                        value: (ENABLE_SETTLEMENT_PERMISSION_CHECKING
                            ? await pb.can(UserPermissionBridge, PERMISSION_TYPES.SETTLE_TRANSACTIONS, state.userTypeId)
                            : true)
                    },
                    {
                        key: PERMISSIONS.EMPLOYEE_CLOCK_IN,
                        value: (ENABLE_EMPLOYEE_CLOCK_IN_PERMISSION_CHECKING
                            ? await pb.can(LocationBridge, PERMISSION_TYPES.EMPLOYEE_CLOCK_IN_APPROVER_ON_SITE)
                            : false)
                    },
                    {key: PERMISSIONS.LABEL_PRINTING, value: await pb.can(LocationBridge, PERMISSION_TYPES.LABEL_PRINTING) },
                    {key: PERMISSIONS.OLD_ZREAD_FORMAT, value: await pb.can(LocationBridge, PERMISSION_TYPES.GENERATE_OLD_ZREAD_FORMAT)},
                    {key: PERMISSIONS.ROOM_PAYMENT, value: await pb.can(LocationBridge, PERMISSION_TYPES.ROOM_PAYMENT)},
                    {key: PERMISSIONS.REPRINT_PMIX, value: await pb.can(UserPermissionBridge, PERMISSION_TYPES.REPRINT_PMIX, state.userTypeId)},
                    {key: PERMISSIONS.PRINT_X_PMIX, value: await lb.getColumnValue('print_x_pmix') == 1},
                    {key: PERMISSIONS.PRINT_PMIX, value: await lb.getColumnValue('print_pmix') == 1},
                    {key: PERMISSIONS.PMIX_COMBINE_SERVICE_TYPE, value: await lb.getColumnValue('pmix_combine_service_type') == 1}, // TODO: reach out to mobile devs about this.
                ];

                for (const index in permissions) {
                    commit('setPermissionKey', {
                        key: permissions[index]['key'],
                        value: permissions[index]['value'],
                    });
                }

                return;
            }

            commit('setPermissionKey', {
                key: PERMISSIONS.PMIX_COMBINE_SERVICE_TYPE,
                value: await dbService.fetchColumnValue(DEXIE_TABLES.LOCATIONS, state.locationId, 'pmix_combine_service_type') == 1,
            });
            
            commit('setPermissionKey', {
                key: PERMISSIONS.PRINT_X_PMIX,
                value: await dbService.fetchColumnValue(DEXIE_TABLES.LOCATIONS, state.locationId, 'print_x_pmix') == 1,
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.EOD_REPORT,
                value: await dbService.fetchPermission('EOD Report', DEXIE_TABLES.USER_TYPE_PERMISSIONS, state.userTypeId),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.PRINT_PMIX,
                value: await dbService.fetchColumnValue(DEXIE_TABLES.LOCATIONS, state.locationId, 'print_pmix') == 1,
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.PAX_DISABLED,
                value: await dbService.fetchPermission('Pax Disabled', DEXIE_TABLES.LOCATIONS),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.PLACE_ORDER,
                value: (ENABLE_PLACE_ORDER_PERMISSION_CHECKING
                    ? await dbService.fetchPermission('Create Transactions', DEXIE_TABLES.USER_TYPE_PERMISSIONS, state.userTypeId)
                    : true),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.SETTLE_ORDER,
                value: (ENABLE_SETTLEMENT_PERMISSION_CHECKING
                    ? await dbService.fetchPermission('Settle Transactions', DEXIE_TABLES.USER_TYPE_PERMISSIONS, state.userTypeId)
                    : true
                ),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.EMPLOYEE_CLOCK_IN,
                value: (ENABLE_EMPLOYEE_CLOCK_IN_PERMISSION_CHECKING
                    ? await dbService.fetchPermission('Employee clock in, approver on site', DEXIE_TABLES.LOCATIONS)
                    : false),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.LABEL_PRINTING,
                value: await dbService.fetchPermission('Label Printing', DEXIE_TABLES.LOCATIONS),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.OLD_ZREAD_FORMAT,
                value: await dbService.fetchPermission('Generate old Zread format', DEXIE_TABLES.LOCATIONS),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.ROOM_PAYMENT,
                value: await dbService.fetchPermission('Room Payment', DEXIE_TABLES.LOCATIONS),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.REPRINT_PMIX,
                value: await dbService.fetchPermission('Reprint PMIX', DEXIE_TABLES.USER_TYPE_PERMISSIONS, state.userTypeId),
            });

            commit('setPermissionKey', {
                key: PERMISSIONS.CASH_FUND_MANAGEMENT,
                value: await dbService.fetchPermission('Cash Fund Management', DEXIE_TABLES.LOCATIONS),
            });
        },
    },

    getters: {
        multiTerminalType(state) {
            return state.accountTypeId === AccountTypes.BM
                ? MultiTerminalTypes.REALTIME
                : MultiTerminalTypes.ZREAD;
        },

        permissions(state) {
            if (!state.isMultiTerminal) {
                return {
                    [PERMISSIONS.DAY_END]: true,
                    [PERMISSIONS.SHIFT_CHANGE]: true,
                    [PERMISSIONS.SET_CASH_FLOAT]: true,
                    [PERMISSIONS.APPLY_DISCOUNT_TO_SPLIT]: true,
                    ...state.permissions
                };
            }

            return {
                ...state.permissions,
                [PERMISSIONS.SETTLE_ORDER]: state.terminalId == 1,
                [PERMISSIONS.PLACE_ORDER]: true,
                [PERMISSIONS.DAY_END]: state.terminalId == 1,
                [PERMISSIONS.SHIFT_CHANGE]: state.terminalId == 1,
                [PERMISSIONS.SET_CASH_FLOAT]: terminal.id == 1,
                [PERMISSIONS.APPLY_DISCOUNT_TO_SPLIT]: true,
            };
        }
    },
}
